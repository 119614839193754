var app = angular.module('App');

app.filter('cicadaCurrency', ["$filter", function ($filter) {
    return function (amount, currencySymbol) {
        var currency = $filter('currency');

        if (!amount) {
            return currency(0, currencySymbol);
        }

        if (amount.toString().charAt(0) === "-") {
            return currency(amount, currencySymbol).replace("-", "").replace("(", "").replace(")", "") + "-";
        }

        return currency(amount, currencySymbol);
    };
}]);

app.filter('cicadaDisplayCurrency', ["$filter", function ($filter) {
  return function (amount, currencySymbol) {
    var currency = $filter('currency');

    if (!amount) {
      return currency(0, currencySymbol);
    }

    if (amount.toString().charAt(0) === "-") {
      return "-" + currency(amount, currencySymbol).replace("-", "").replace("(", "").replace(")", "");
    }

    return currency(amount, currencySymbol);
  };
}]);

app.filter('capitalize', ["$filter", function ($filter) {
    return function (input) {
        return (!!input) ? input.replace(/([^\W_]+[^\s-]*) */g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }) : '';
    };
}]);

app.filter("dateRange", function () {
  return function (items, datePropertyName, from, to) {
    result = [];
    result = _.filter(items, function (i) {
      return (new Date(i[datePropertyName]).setHours(0, 0, 0, 0)) >= from.setHours(0, 0, 0, 0)
        && (new Date(i[datePropertyName]).setHours(0, 0, 0, 0)) <= to.setHours(0, 0, 0, 0);
    });
    return result;
  };
});

app.filter('cicadaNumber', ["$filter", function ($filter) {
  return function (number, fractionSize) {
    if (!number) { return '-'; }

    var numberFilter = $filter('number');
    return numberFilter(number, fractionSize);
  };
}]);
app.filter('yesNo', function () {
  return function (input) {
    return input ? 'Yes' : 'No';
  }
});
